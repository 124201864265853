:root { 
--white_A700_00:#ffffff00; 
--gray_700:#666666; 
--black_900_a2:#000000a2; 
--black_900_0a:#0000000a; 
--black_900:#000000; 
--indigo_50:#e9edf8; 
--yellow_900:#de782e; 
--bluegray_100:#cccccc; 
--gray_200:#eeeeee; 
--black_900_14:#00000014; 
--white_A700:#ffffff; 
--indigo_600:#254abc; 
}